import { Button, ButtonGroup } from '@shopify/polaris'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Modal from '../../../components/Modal'
import useAppDispatch from '../../../hooks/useAppDispatch'
import { fetchDeleteLanguageForPDF, fetchDeletePeppolNumber } from '../../../redux/features/look-up/lookUpSlice'

const DeleteModalForSettings = ({show, onClose, data, block, reUpdateData}) => {
	const {fetchingDeleteLanguageForPDF, fetchingDeletePeppolNumber} = useSelector(state => state.lookUp)
	const {t} = useTranslation()
	const dispatch = useAppDispatch()
	const isLoading = fetchingDeleteLanguageForPDF || fetchingDeletePeppolNumber
	console.log('block', block)
	const deleteRequestHandler = async () => {
		try {
			if (block === 'languageBlock') {
				await dispatch(fetchDeleteLanguageForPDF(data?.id))
				onClose()
				reUpdateData()
			}
			if (block === 'peppolBlock') {
				await dispatch(fetchDeletePeppolNumber(data?.id))
				onClose()
				reUpdateData()
			}
		} catch (e) {
			console.error(e)
		}
	}
	
	
	const renderContentMessage = () => {
		if (block === 'languageBlock') {
			return <p >
					{'Do you want to delete the'}{" "}
					<b>{data.country?.name}</b> with the language for PDF set to <b>{data?.language?.name}</b>?
				</p >
		}
		if (block === 'peppolBlock') {
			return <p >
				{'Are you sure you want to delete the Peppol number'}
				{" "}
				<b >{data.peppol_number}</b >
					?
			</p >
		}
	}
	
	return (
		<Modal
			title={'Delete'}
			visible={show}
			iconType={"danger"}
			description={renderContentMessage()}
			footer={
				<ButtonGroup fullWidth>
            <Button
	            onClick={onClose}
	            size='large'
            >
              {t("messages.cancel")}
            </Button >
            <Button
	            onClick={deleteRequestHandler}
	            tone={'critical'}
	            variant={'plain'}
	            size='large'
	            loading={isLoading}
            >
              {t("messages.delete")}
            </Button >
          </ButtonGroup >
			}
			onClose={onClose}
		/>
	)
}

export default DeleteModalForSettings