import React, { useEffect } from "react";
import {
  Text, BlockStack, Box,
  Button,
  ButtonGroup,
  Card,
  FormLayout, InlineStack,
  Select,
} from "@shopify/polaris";
import { useSelector } from "react-redux";
import useActionDialogs from "hooks/useActionDIalogs";
import { getCustomersList } from '../../../../redux/features/documents/documentsSlice'
import { CustomerModal } from "../../modals";
import useAppDispatch from "hooks/useAppDispatch";

const ActionDialogs = Object.freeze({
  customer: "customer",
});

const Customer = ({ data, setData }) => {
  const dispatch = useAppDispatch();
  const { customerId } = data;
  const { customers } = useSelector((state) => state.documents);
  const currentCustomer =
    customers.find(({ id }) => {
      return String(customerId) === String(id)
    }) ?? {};
  const { actionDialogs, handleActionDialogsOpen, handleActionDialogsClose } =
    useActionDialogs([ActionDialogs.customer]);

  useEffect(() => {
    dispatch(getCustomersList());
  }, []);
  
  
  useEffect(() => {
    try {
      !actionDialogs?.customer?.open && dispatch(getCustomersList());
    } catch (e) {
      console.error(e)
    }
  }, [actionDialogs?.customer?.open]);

  const onAddNewCustomer = () => {
    handleActionDialogsOpen(ActionDialogs.customer, { type: "create" });
  };

  const onEditCustomer = () => {
    handleActionDialogsOpen(ActionDialogs.customer, {
      type: "update",
      ...currentCustomer,
    });
  };

  const onCustomerSelect = (id) => {
    setData((prev) => ({
      ...prev,
      customerId: Number(id), // Приводим id к числу
    }));
  };

  const customersOptions = [
    "",
    ...customers.map(({ id, name }) => ({
      value: String(id),
      label: name,
    })),
  ];

  return (
    <div style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}>
      <FormLayout>
        <FormLayout.Group>
          <Select
            label='Select or add customer'
            options={customersOptions}
            onChange={onCustomerSelect}
            value={String(customerId) || ""}
          />

          <div style={{ marginTop: "1.5rem" }}>
            <ButtonGroup>
              <Button size={'micro'} variant={'primary'} onClick={onAddNewCustomer}>
                Add new customer
              </Button>
            </ButtonGroup>
          </div>
        </FormLayout.Group>
        {customerId && (
          <FormLayout.Group>
            <Card sectioned>
             <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
              <BlockStack gap="200">
                <InlineStack align="start" gap="100">
                  <Text as="span" fontWeight="medium">Name:</Text>
                  <Text as="span" tone="subdued">{currentCustomer?.name || "—"}</Text>
                </InlineStack>
                <InlineStack align="start" gap="100">
                  <Text as="span" fontWeight="medium">VAT number:</Text>
                  <Text as="span" tone="subdued">{currentCustomer?.tax_number || "—"}</Text>
                </InlineStack>
                <InlineStack align="start" gap="100">
                  <Text as="span" fontWeight="medium">Email:</Text>
                  <Text as="span" tone="subdued">{currentCustomer?.email || "—"}</Text>
                </InlineStack>
                <InlineStack align="start" gap="100">
                  <Text as="span" fontWeight="medium">Country:</Text>
                  <Text as="span" tone="subdued">{currentCustomer?.country?.name ? currentCustomer?.country?.name : currentCustomer?.country || "—"}</Text>
                </InlineStack>
                <InlineStack align="start" gap="100">
                  <Text as="span" fontWeight="medium">Address:</Text>
                  <Text as="span" tone="subdued">{currentCustomer?.address_line_1 || "—"}</Text>
                </InlineStack>
              </BlockStack>
            
              <div style={{width:'100%', display: 'flex', justifyContent: 'center'}}>
                <Button onClick={onEditCustomer} variant="plain">
                  Edit customer
                </Button>
              </div>
            </div>
            </Card>
            <br />
          </FormLayout.Group>
        )}
      </FormLayout>

      <CustomerModal
        onClose={() => handleActionDialogsClose(ActionDialogs.customer)}
        isOpen={actionDialogs.customer.open}
        data={actionDialogs.customer.cellData}
      />
    </div>
  );
};

export default Customer;
