import { Button, ButtonGroup, Page, Select, Text, TextField } from '@shopify/polaris'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import GoBackPageNavigation from '../../components/GoBackPageNavigation/GoBackPageNavigation'
import SaveBar from '../../components/SaveBar/SaveBar'
import SearchingCountry from '../../components/SearchingCountry/SearchingCountry'
import useAppDispatch from '../../hooks/useAppDispatch'
import ArrowLeftBack from '../../img/arrow-left-for-button-back.svg'
import ArrowRight from '../../img/arrow-right-white.svg'
import {
	addCustomer,
	editCustomer,
	getCountriesAllList,
	getLanguagesList
} from '../../redux/features/customers/customersSlice'

const sendingMethodOptions = [
	{
		value: 'Peppol',
		label: 'Peppol'
	},
	{
		value: 'Email',
		label: 'Email'
	},
]

const defaultFormValues = {
	name: '',
	peppol_id: '',
	contact_person: '',
	email: '',
	language: '',
	country_id: '',
	city: '',
	state: '',
	address_line_1: '',
	address_line_2: '',
	postal_code: '',
	tax_number: '',
	sending_method: '',
}

const AddCustomer = () => {
	const {languages_list, countries_list, addCustomerLoader, editCustomerLoader} = useSelector(state => state.customers)
	const [formValues, setFormValues] = useState(defaultFormValues)
	const [languagesList, setLanguagesList] = useState([])
	const [countriesAllList, setCountriesAllList] = useState([])
	const [changeCountryValue, setChangeCountryValue] = useState(null)
	const {t} = useTranslation()
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const location = useLocation()
	const editFormValues = location.state?.customerValue || {}
	
	useEffect(() => {
		const request = async () => {
			try {
				await dispatch(getLanguagesList())
				await dispatch(getCountriesAllList())
				if (editFormValues?.id) {
					setFormValues(editFormValues)
					setChangeCountryValue(editFormValues?.country?.name)
				}
			} catch (error) {
				console.log(error)
			}
		}
		request()
	}, [])
	
	const handleChange = (field) => (value) => {
		setFormValues((prevState) => ({
			...prevState,
			[field]: value,
		}))
	}
	
	useEffect(() => {
		languages_list?.length > 0 && setLanguagesList(
			languages_list?.map(lang => ({
				label: lang.name,
				value: lang.code
			}))
		)
		countries_list?.length > 0 && setCountriesAllList(
			countries_list?.map(c => ({
				label: c.name,
				value: c.code
			}))
		)
	}, [languages_list, countries_list])
	
	const handleSearchChange = (newValue) => {
		const selectedCountry = countriesAllList.find(c => c.value === newValue)
		if (selectedCountry) {
			setChangeCountryValue(selectedCountry.label)
			setFormValues((prevState) => ({
				...prevState,
				country_id: selectedCountry.value,
			}))
		}
	}
	
	const handleSubmit = async () => {
		const tehData = {
			...formValues,
		}
		try {
			let res = ''
			if (editFormValues?.id) {
				res = await dispatch(editCustomer({...tehData, id: editFormValues?.id}))
			} else {
				res = await dispatch(addCustomer(tehData))
			}
			if (res?.status === 200) {
				setFormValues(defaultFormValues)
				setChangeCountryValue(null)
				goBack()
			}
		} catch (e) {
		
		}
	}
	
	const goBack = () => {
		navigate(-1)
	}
	return (
		<Page
			fullWidth
			title={
				<GoBackPageNavigation
					content={
						<Text
							variant='heading2xl'
							as='span'
						>
         {editFormValues?.id ? 'Edit Customer' : 'Add New Customer'}
        </Text >
					}
				/>
			}
		>
			<div className={'block-white'} style={{paddingBottom: 100}}>
				<div style={{width: '50%'}}>
					<TextField
						label='Name'
						value={formValues.name}
						onChange={handleChange('name')}
						placeholder='Enter your Business Name'
						requiredIndicator={true}
					/>
					<br />
		      <TextField
			      label='Peppol ID'
			      value={formValues.peppol_id}
			      onChange={handleChange('peppol_id')}
			      placeholder='Enter your Peppol ID'
			      requiredIndicator={true}
		      />
					<br />
		      <TextField
			      label='Contact Person'
			      value={formValues.contact_person}
			      onChange={handleChange('contact_person')}
			      placeholder='Enter your Contact Person'
			      requiredIndicator={true}
		      />
					<br />
		      <TextField
			      label='Invoicing Email'
			      value={formValues.email}
			      onChange={handleChange('email')}
			      placeholder='Enter your Invoicing Email'
			      requiredIndicator={true}
		      />
					<br />
					<Select
						id='language'
						name='language'
						label={'Preferred Language For PDF'}
						placeholder={'Enter your Preferred Language For PDF'}
						required={true}
						options={languagesList || []}
						onChange={handleChange('language')}
						value={formValues.language}
					/>
					<br />
					<SearchingCountry
						id='country_id'
						title={
							<>
              Country <span style={{color: 'red'}}>*</span >
            </>}
						placeholder={'Enter your Country'}
						onChange={handleSearchChange}
						changeCountry={changeCountryValue}
						dataCountriesList={countriesAllList}
						isOpen={true}
					/>
					<br />
		      <TextField
			      label='City'
			      value={formValues.city}
			      onChange={handleChange('city')}
			      placeholder='Enter your City'
			      requiredIndicator={true}
		      />
					<br />
		      <TextField
			      label='State'
			      value={formValues.state}
			      onChange={handleChange('state')}
			      placeholder='Enter your State'
		      />
					<br />
		      <TextField
			      label='Address Line 1 (Street)'
			      value={formValues.address_line_1}
			      onChange={handleChange('address_line_1')}
			      placeholder='Enter your Address Line 1 (Street)'
			      requiredIndicator={true}
		      />
					<br />
		      <TextField
			      label='Address Line 2 (Street)'
			      value={formValues.address_line_2}
			      onChange={handleChange('address_line_2')}
			      placeholder='Enter your Address Line 2 (Street)'
		      />
					<br />
		      <TextField
			      label='Postal Code'
			      value={formValues.postal_code}
			      onChange={handleChange('postal_code')}
			      placeholder='Enter your PostalCode'
			      requiredIndicator={true}
		      />
					<br />
		      <TextField
			      label='Tax Number'
			      value={formValues.tax_number}
			      onChange={handleChange('tax_number')}
			      placeholder='Enter your Tax Number'
			      requiredIndicator={true}
		      />
					<br />
					<Select
						id='sending_method'
						name='sending_method'
						label={'Sending Method'}
						required={true}
						options={sendingMethodOptions || []}
						onChange={handleChange('sending_method')}
						value={formValues.sending_method}
						requiredIndicator={true}
						placeholder='Enter your Sending Method'
					/>
				</div >
			</div >
			<SaveBar >
        <ButtonGroup >
          <Button
	          size={'micro'}
	          onClick={goBack}
          > <img
	          src={ArrowLeftBack}
	          alt='arrow'
	          style={{width: 10, height: 10, marginRight: 8}}
          />{t("eprReporting.back")}
					</Button >
          <Button
	          size={'micro'}
	          variant='primary'
	          // disabled={isNextButtonDisabled}
	          loading={addCustomerLoader || editCustomerLoader}
	          onClick={handleSubmit}
          >
            {editFormValues?.id ? 'Save Customer' : 'Add Customer'}
	          <img
	          src={ArrowRight}
	          alt='arrow'
	          style={{width: 10, height: 10, marginLeft: 8}}
          />
          </Button >
        </ButtonGroup >
      </SaveBar >
		</Page >
	)
}

export default AddCustomer