import React, { useEffect, useState } from "react"
import { Button, ButtonGroup, FormLayout, Select, TextField } from "@shopify/polaris"
import Modal from "components/Modal"
import useAppDispatch from "hooks/useAppDispatch"
import { useSelector } from "react-redux"
import SearchingCountry from '../../../../components/SearchingCountry/SearchingCountry'
import { getCountriesAllList, getLanguagesList } from '../../../../redux/features/customers/customersSlice'
import { addCustomerToList, editCustomerInList } from '../../../../redux/features/documents/documentsSlice'
import { fetchCountries } from '../../../../redux/features/other/otherSlice'

const sendingMethodOptions = [
  {
    value: '',
    label: ''
  },
  {
    value: 'Peppol',
    label: 'Peppol'
  },
  {
    value: 'Email',
    label: 'Email'
  },
]

const Customer = (props) => {
  const {languages_list, countries_list} = useSelector(state => state.customers)
  const { addingCustomerLoading, editingCustomerLoading } = useSelector(state => state.documents)
  const dispatch = useAppDispatch()
  const { data, onClose: handleClose, isOpen } = props
  
  const [customerData, setCustomerData] = useState({})
  const [languagesList, setLanguagesList] = useState([])
  const [countriesAllList, setCountriesAllList] = useState([])
  
  useEffect(() => {
    const request = async () => {
      try {
        await dispatch(getLanguagesList())
        await dispatch(getCountriesAllList())
        await dispatch(fetchCountries())
      } catch (error) {
        console.log(error)
      }
    }
    request()
  }, [])
  
  useEffect(() => {
    languages_list?.length && setLanguagesList(
      [
        { label: '', value: '' },
        ...languages_list.map(lang => ({ label: lang.name, value: lang.code }))
      ]
    )
    countries_list?.length && setCountriesAllList(
      countries_list.map(c => ({ label: c.name, value: c.code }))
    )
  }, [languages_list, countries_list])
  
  useEffect(() => {
    if (isOpen && data?.type === "update") {
      const { country, ...rest } = data
      setCustomerData({
        ...rest,
        country_id: country?.code || '',
      })
    } else {
      setCustomerData({})
    }
  }, [isOpen, data])
  
  const onChange = (id, newValue) => {
    setCustomerData((prev) => ({
      ...prev,
      [id]: newValue,
    }))
  }
  
  const handleSearchChange = (selected) => {
    setCustomerData((prev) => ({
      ...prev,
      country_id: selected || '',
    }))
  }
  
  const getCountryLabel = (code) => {
    const found = countriesAllList.find(c => c.value === code)
    return found?.label || ''
  }
  
  const isSubmitDisabled = [
    'name',
    'email',
    'tax_number',
    'address_line_1',
    'postal_code',
    'city',
    'sending_method',
    'country_id'
  ].some((key) => !customerData[key])
  
  const clearCustomerInfo = () => setCustomerData({})
  
  const getModalPropertiesByType = (type) => {
    switch (type) {
      case "create":
        return {
          loading: addingCustomerLoading,
          title: "Add new customer",
          submitAction: addCustomerToList,
          submitButton: "Submit",
        }
      case "update":
        return {
          loading: editingCustomerLoading,
          title: `Edit ${data?.name}`,
          submitAction: editCustomerInList,
          submitButton: "Edit",
        }
      default:
        return {}
    }
  }
  
  const { loading, title, submitAction, submitButton } = getModalPropertiesByType(data?.type)
  
  const onClose = () => {
    handleClose()
    clearCustomerInfo()
  }
  
  const onSubmit = () => {
    dispatch(submitAction(customerData)).then(() => {
      onClose()
    })
  }
  
  return (
    <Modal
      title={title}
      infoIconVisible={false}
      visible={isOpen}
      onClose={onClose}
      content={
        <FormLayout>
          <TextField
            label='Name'
            value={customerData.name || ''}
            onChange={(val) => onChange('name', val)}
            requiredIndicator
          />
          <TextField
            label='Peppol ID'
            value={customerData.peppol_id || ''}
            onChange={(val) => onChange('peppol_id', val)}
          />
          <TextField
            label='Contact Person'
            value={customerData.contact_person || ''}
            onChange={(val) => onChange('contact_person', val)}
          />
          <TextField
            label='Invoicing Email'
            type='email'
            value={customerData.email || ''}
            onChange={(val) => onChange('email', val)}
            requiredIndicator
          />
          <Select
            id='language'
            label='Preferred Language For PDF'
            options={languagesList}
            onChange={(val) => onChange('language', val)}
            value={customerData.language || ''}
          />
          <SearchingCountry
            id='country_id'
            title={
              <>Country <span style={{ color: 'red' }}>*</span></>
            }
            onChange={handleSearchChange}
            changeCountry={getCountryLabel(customerData.country_id)}
            dataCountriesList={countriesAllList}
            isOpen={true}
          />
          <TextField
            label='City'
            value={customerData.city || ''}
            onChange={(val) => onChange('city', val)}
            requiredIndicator
          />
          <TextField
            label='State'
            value={customerData.state || ''}
            onChange={(val) => onChange('state', val)}
          />
          <TextField
            label='Address Line 1 (Street)'
            value={customerData.address_line_1 || ''}
            onChange={(val) => onChange('address_line_1', val)}
            requiredIndicator
          />
          <TextField
            label='Address Line 2 (Street)'
            value={customerData.address_line_2 || ''}
            onChange={(val) => onChange('address_line_2', val)}
          />
          <TextField
            label='Postal Code'
            value={customerData.postal_code || ''}
            onChange={(val) => onChange('postal_code', val)}
            requiredIndicator
          />
          <TextField
            label='Tax Number'
            value={customerData.tax_number || ''}
            onChange={(val) => onChange('tax_number', val)}
            requiredIndicator
          />
          <Select
            id='sending_method'
            label='Sending Method'
            options={sendingMethodOptions}
            onChange={(val) => onChange('sending_method', val)}
            value={customerData.sending_method || ''}
            requiredIndicator
          />
        </FormLayout>
      }
      footer={
        <ButtonGroup fullWidth>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            variant='primary'
            onClick={onSubmit}
            loading={loading}
            disabled={isSubmitDisabled}
          >
            {submitButton}
          </Button>
        </ButtonGroup>
      }
    />
  )
}

export default Customer