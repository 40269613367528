import React, { Component } from "react";
import { login, logout } from '../../../../redux/features/auth/authSlice'
import AuthContainer from "../../AuthContainer";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import withRouter from "helpers/withRouter";
import { isEmail } from "utils/validation";
import {
	Button,
	Checkbox,
	FormLayout,
	TextField,
	Text,
	Box,
} from "@shopify/polaris";

import passwordIMG from "img/password.svg";
import emailIMG from "img/email.svg";
import PageHelmet from "components/PageHelmet";
import { createCustomDispatch } from "helpers/customDispatch";

class LogIn extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			passwordChecked: false,
			email: "",
			errorEmail: false,
			password: "",
			errorPassword: false,
		};
	}
	
	handleChangeCheckedRememberPassword = (value) => {
		this.setState({passwordChecked: value});
	};
	
	validateEmail = (email) => {
		const {t} = this.props;
		if (email.length > 0 && !isEmail(email)) {
			this.setState({
				errorEmail: t("login.invalidEmail"),
			});
		} else {
			this.setState({
				errorEmail: false,
			});
		}
	};
	
	doLogin = () => {
		const {t} = this.props;
		const {email, password} = this.state;
		
		if (!password) {
			this.setState({
				errorPassword: t("login.enterPassAndEmail"),
			});
			return;
		}
		
		this.props
			.login(email, password)
			.then((res) => {
				// console.log('res', res)
				if (res.two_factor_auth) {
					this.props.navigate("/confirm-2fa", {state: {...res}});
				} else {
					// if (res?.model_name === 'tax') this.props.navigate("/dashboard")
					// if (res?.model_name === 'tax') this.props.navigate("/einvoice/documents")
					// if (res?.model_name === 'tax') window.location.replace("/einvoice/documents")
					// else if (res?.model_name === 'einvoice') window.location.replace("/einvoice/documents")
				}
			})
			.catch((resp) => {
				this.setState({
					errorPassword: resp.error ? resp.error : t("login.err"),
				});
			});
	};
	
	render() {
		const {t, loggingIn} = this.props;
		const {passwordChecked, email, errorEmail, password, errorPassword} =
			this.state;
		
		return (
			<AuthContainer
				title={"Welcome back!"}
				content={
					<form autoComplete='off'>
            <PageHelmet title={"Log In"} />

            <FormLayout >
              <TextField
	              name='email'
	              type='email'
	              label={
		              <Text
			              color='subdued'
			              variant='bodySm'
		              >
                    {t("login.email")}
                  </Text >
	              }
	              placeholder={t("login.email")}
	              autoComplete='email'
	              value={email}
	              prefix={
		              <img
			              src={emailIMG}
			              alt='email'
			              style={{marginTop: 6}}
		              />
	              }
	              error={errorEmail}
	              onBlur={() => this.validateEmail(email)}
	              onChange={(value) => {
		              this.setState({email: value});
	              }}
              />
              <TextField
	              name='password'
	              type='password'
	              label={
		              <Text
			              color='subdued'
			              variant='bodySm'
		              >
                    {t("login.password")}
                  </Text >
	              }
	              placeholder={t("login.password")}
	              value={password}
	              prefix={
		              <img
			              src={passwordIMG}
			              alt='password'
			              style={{marginTop: 6}}
		              />
	              }
	              error={errorPassword}
	              onChange={(value) => {
		              this.setState({
			              password: value,
			              errorPassword: false,
		              });
	              }}
              />
              <Link
	              className='Polaris-Link'
	              to='/forgot'
              >
                {t("login.forgotPass")}
              </Link >
            </FormLayout >
          </form >
				}
				secondaryContent={
					<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}>
            <h3 style={{marginRight: 10}}>
              Don't have an account?
            </h3 >
            <div style={{width: '100px'}}>
              <Button
	              fullWidth
	              variant='secondary'
	              onClick={() => this.props.navigate("/signup")}
              >
                Sign up
              </Button >
            </div >
          </div >
				}
				actions={
					<>
						<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              <Checkbox
	              checked={passwordChecked}
	              label={t("login.rememberMe")}
	              onChange={this.handleChangeCheckedRememberPassword}
              />
              <Button
	              disabled={loggingIn}
	              loading={loggingIn}
	              variant='primary'
	              onClick={this.doLogin}
              >
                {t("login.signIn")}
              </Button >
            </div >
						
           <div style={{marginTop: 20, textAlign: 'center', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              <Text
	              variant='bodyMd'
	              color='subdued'
              >
                Login in with Xero
              </Text >
              <div>
                <Button
	                variant='primary'
	                onClick={async () => {
		                const backendUrl = "https://dev.vatcompliance.co/api/1/login/xero/";
		                
		                try {
			                const res = await fetch(backendUrl, {
				                method: "GET",
				                mode: "cors",
			                });
			                
			                const queryString = await res.text();
			                console.log("Query from backend:", queryString);
			                console.log("res", res);
			                const xeroAuthUrl = `https://login.xero.com/identity/connect/authorize?${queryString}`;
			                
			                window.location.href = xeroAuthUrl;
		                } catch (err) {
			                console.error("Error fetching Xero login URL:", err);
		                }
	                }}
                >
                  Log in with Xero
                </Button >
              </div >
            </div >
          
        </>
					
				}
			/>
		);
	}
}

const mapStateToProps = (state) => ({
	loginError: state.auth.loginError,
	loggingIn: state.auth.loggingInLoading,
});

const mapDispatchToProps = (defaultDispatch) => {
	const dispatch = createCustomDispatch(defaultDispatch);
	
	return {
		login: (email,
			password) => dispatch(login({email, password})),
		logout: () => dispatch(logout()),
	};
};

export default withTranslation()(
	connect(mapStateToProps, mapDispatchToProps)(withRouter(LogIn))
);
