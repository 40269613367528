import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import callApi from "helpers/callApi";
import { parseServerError } from "utils/errors";


export const fetchLookUp = createAsyncThunk(
  "look-up/fetchLookUp",
  async (params, { rejectWithValue }) => {
    try {
      const response = await callApi("/peppol/lookup", "POST", params);
      if (!response.ok) {
        throw new Error("Server error");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchPeppolCountriesList = createAsyncThunk(
  "look-up/fetchPeppolCountriesList",
  async (params, { rejectWithValue }) => {
    try {
      const response = await callApi("/peppol/countries", "GET");
      if (!response.ok) {
        throw new Error("Server error");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchPeppolDataRegistration= createAsyncThunk(
  "look-up/fetchPeppolDataRegistration",
  async (params, { rejectWithValue }) => {
    try {
      const response = await callApi("/peppol/data_registration", "GET");
      if (!response.ok) {
        throw new Error("Server error");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


export const fetchAddPeppolNumber = createAsyncThunk(
  "look-up/fetchAddPeppolNumber",
  async (params, { rejectWithValue }) => {
    try {
      const response = await callApi("/peppol_number", "POST", params);
      if (!response.ok) {
        throw new Error("Server error");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchGetPeppolNumber = createAsyncThunk(
  "look-up/fetchGetPeppolNumber",
  async (params, { rejectWithValue }) => {
    try {
      const response = await callApi("/peppol_number?is_active=false", "GET");
      if (!response.ok) {
        throw new Error("Server error");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchAddPeppolRegistration = createAsyncThunk(
  "look-up/fetchAddPeppolRegistration",
  async (params, { rejectWithValue }) => {
    try {
      const response = await callApi("/peppol/registration", "POST", params);
      if (!response.ok) {
        throw new Error("Server error");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchEditPeppol = createAsyncThunk(
  "look-up/fetchEditPeppol",
  async (params, { rejectWithValue }) => {
    try {
      const response = await callApi("/manager/peppol_number/edit", "POST", params);
      if (!response.ok) {
        throw new Error("Server error");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchCountriesLanguage = createAsyncThunk(
  "look-up/CountriesLanguage",
  async (params, { rejectWithValue }) => {
    try {
      const response = await callApi("/documents_settings/countries_language", "GET");
      if (!response.ok) {
        throw new Error("Server error");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchLanguages = createAsyncThunk(
  "look-up/fetchLanguages",
  async (params, { rejectWithValue }) => {
    try {
      const response = await callApi("/peppol/languages", "GET");
      if (!response.ok) {
        throw new Error("Server error");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchAddLanguageForPDF = createAsyncThunk(
  "look-up/fetchAddLanguageForPDF",
  async (params, { rejectWithValue }) => {
    try {
      const response = await callApi("/documents_settings/countries_language/add/", "POST", params);
      if (!response.ok) {
        throw new Error("Server error");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchEditLanguageForPDF = createAsyncThunk(
  "look-up/fetchEditLanguageForPDF",
  async (params, { rejectWithValue }) => {
    try {
      const response = await callApi("/documents_settings/countries_language/edit/", "POST", params);
      if (!response.ok) {
        throw new Error("Server error");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const fetchDeleteLanguageForPDF = createAsyncThunk(
  "look-up/fetchDeleteLanguageForPDF",
  async (id, { rejectWithValue }) => {
    try {
      // Не передаем body вообще
      const response = await callApi(
        `/documents_settings/countries_language/delete/${id}`,
        "DELETE"
      );
      
      if (!response.ok) {
        throw new Error("Server error");
      }
      
      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchDeletePeppolNumber = createAsyncThunk(
  "look-up/fetchDeletePeppolNumber",
  async (id, { rejectWithValue }) => {
    try {
      // Не передаем body вообще
      const response = await callApi(
        `/manager/peppol_number/${id}`,
        "DELETE"
      );
      
      if (!response.ok) {
        throw new Error("Server error");
      }
      
      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


const initialState = {
  look_up: [],
  countries_list: [],
  data_registration: [],
  peppol_number_list: [],
  countries_language: [],
  languages: [],
  registrationMessage: null
};

const lookUpSlice = createSlice({
  name: "look-up",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchLookUp.pending, (state) => {
        state.fetchingLookUp = true;
      })
      .addCase(fetchLookUp.fulfilled, (state, action) => {
        state.fetchingLookUp = false;
        state.fetchingLookUpError = null;
        state.look_up = action.payload.companies;
      })
      .addCase(fetchLookUp.rejected, (state, action) => {
        state.fetchingLookUp = false;
        state.fetchingLookUpError = parseServerError(action.error);
      })
      
      .addCase(fetchPeppolCountriesList.pending, (state) => {
        state.fetchingPeppolCountriesList = true;
      })
      .addCase(fetchPeppolCountriesList.fulfilled, (state, action) => {
        state.fetchingPeppolCountriesList = false;
        state.fetchingPeppolCountriesListError = null;
        state.countries_list = action.payload;
      })
      .addCase(fetchPeppolCountriesList.rejected, (state, action) => {
        state.fetchingPeppolCountriesList = false;
        state.fetchingPeppolCountriesListError = parseServerError(action.error);
      })
      
      .addCase(fetchPeppolDataRegistration.pending, (state) => {
        state.fetchingPeppolDataRegistration = true;
      })
      .addCase(fetchPeppolDataRegistration.fulfilled, (state, action) => {
        state.fetchingPeppolDataRegistration = false;
        state.fetchingPeppolDataRegistrationError = null;
        state.data_registration = action.payload;
      })
      .addCase(fetchPeppolDataRegistration.rejected, (state, action) => {
        state.fetchingPeppolDataRegistration = false;
        state.fetchingPeppolDataRegistrationError = parseServerError(action.error);
      })
      
      .addCase(fetchAddPeppolNumber.pending, (state) => {
        state.fetchingAddPeppolNumber = true;
      })
      .addCase(fetchAddPeppolNumber.fulfilled, (state, action) => {
        state.fetchingAddPeppolNumber = false;
        state.fetchingAddPeppolNumberError = null;
      })
      .addCase(fetchAddPeppolNumber.rejected, (state, action) => {
        state.fetchingAddPeppolNumber = false;
        state.fetchingAddPeppolNumberError = parseServerError(action.error);
      })
      
      .addCase('CLEAR_REGISTRATION_MESSAGE', (state) => {
        state.registrationMessage = null;
      })
      .addCase(fetchAddPeppolRegistration.pending, (state) => {
        state.fetchingAddPeppolRegistration = true;
      })
      .addCase(fetchAddPeppolRegistration.fulfilled, (state, action) => {
        state.fetchingAddPeppolRegistration = false;
        state.fetchingAddPeppolRegistrationError = null;
        state.registrationMessage = action.payload
      })
      .addCase(fetchAddPeppolRegistration.rejected, (state, action) => {
        state.fetchingAddPeppolRegistration = false;
        state.fetchingAddPeppolRegistrationError = parseServerError(action.error);
      })
      
      .addCase(fetchGetPeppolNumber.pending, (state) => {
        state.fetchingGetPeppolNumber = true;
      })
      .addCase(fetchGetPeppolNumber.fulfilled, (state, action) => {
        state.fetchingGetPeppolNumber = false;
        state.fetchingGetPeppolNumberError = null;
        state.peppol_number_list = action.payload
      })
      .addCase(fetchGetPeppolNumber.rejected, (state, action) => {
        state.fetchingGetPeppolNumber = false;
        state.fetchingGetPeppolNumberError = parseServerError(action.error);
      })
      
      .addCase(fetchEditPeppol.pending, (state) => {
        state.fetchingEditPeppol = true;
      })
      .addCase(fetchEditPeppol.fulfilled, (state, action) => {
        state.fetchingEditPeppol = false;
        state.fetchingEditPeppolError = null;
      })
      .addCase(fetchEditPeppol.rejected, (state, action) => {
        state.fetchingEditPeppol = false;
        state.fetchingEditPeppolError = parseServerError(action.error);
      })
      
      .addCase(fetchCountriesLanguage.pending, (state) => {
        state.fetchingCountriesLanguage = true;
      })
      .addCase(fetchCountriesLanguage.fulfilled, (state, action) => {
        state.fetchingCountriesLanguage = false;
        state.fetchingCountriesLanguageError = null;
        state.countries_language = action.payload
      })
      .addCase(fetchCountriesLanguage.rejected, (state, action) => {
        state.fetchingCountriesLanguage = false;
        state.fetchingCountriesLanguageError = parseServerError(action.error);
      })
      
      .addCase(fetchLanguages.pending, (state) => {
        state.fetchingLanguages = true;
      })
      .addCase(fetchLanguages.fulfilled, (state, action) => {
        state.fetchingLanguages = false;
        state.fetchingLanguagesError = null;
        state.languages = action.payload
      })
      .addCase(fetchLanguages.rejected, (state, action) => {
        state.fetchingLanguages = false;
        state.fetchingLanguagesError = parseServerError(action.error);
      })
      
      .addCase(fetchAddLanguageForPDF.pending, (state) => {
        state.fetchingAddLanguageForPDF = true;
      })
      .addCase(fetchAddLanguageForPDF.fulfilled, (state, action) => {
        state.fetchingAddLanguageForPDF = false;
        state.fetchingAddLanguageForPDFError = null;
      })
      .addCase(fetchAddLanguageForPDF.rejected, (state, action) => {
        state.fetchingAddLanguageForPDF = false;
        state.fetchingAddLanguageForPDFError = parseServerError(action.error);
      })
      
      .addCase(fetchEditLanguageForPDF.pending, (state) => {
        state.fetchingEditLanguageForPDF = true;
      })
      .addCase(fetchEditLanguageForPDF.fulfilled, (state, action) => {
        state.fetchingEditLanguageForPDF = false;
        state.fetchingEditLanguageForPDFError = null;
      })
      .addCase(fetchEditLanguageForPDF.rejected, (state, action) => {
        state.fetchingEditLanguageForPDF = false;
        state.fetchingEditLanguageForPDFError = parseServerError(action.error);
      })
      
      .addCase(fetchDeleteLanguageForPDF.pending, (state) => {
        state.fetchingDeleteLanguageForPDF = true;
      })
      .addCase(fetchDeleteLanguageForPDF.fulfilled, (state, action) => {
        state.fetchingDeleteLanguageForPDF = false;
        state.fetchingDeleteLanguageForPDFError = null;
      })
      .addCase(fetchDeleteLanguageForPDF.rejected, (state, action) => {
        state.fetchingDeleteLanguageForPDF = false;
        state.fetchingDeleteLanguageForPDFError = parseServerError(action.error);
      })
      
      .addCase(fetchDeletePeppolNumber.pending, (state) => {
        state.fetchingDeletePeppolNumber = true;
      })
      .addCase(fetchDeletePeppolNumber.fulfilled, (state, action) => {
        state.fetchingDeletePeppolNumber = false;
        state.fetchingDeletePeppolNumberError = null;
      })
      .addCase(fetchDeletePeppolNumber.rejected, (state, action) => {
        state.fetchingDeletePeppolNumber = false;
        state.fetchingDeletePeppolNumberError = parseServerError(action.error);
      })
});

export default lookUpSlice.reducer;
